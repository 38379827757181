<template>
  <div class="ma-0 pa-0">
    <v-container
      v-if="!users.isGuest"
      class="ma-0 pa-0"
    >
      <!-- Default Dashboard Image -->
      <v-row
        v-if="!displayDashboard"
        no-gutters
        class="pt-16 pb-16"
        :style="{ 'background-image': 'url(' + image + ')',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '95%',
        }"
      >
        <v-col
          class="text-center pt-16 pb-16"
          offset-sm="3"
          sm="6"
        >
          <v-img
            :src="require('@/assets/quick-quote-logo.jpg')"
            class="align-center mt-16 mb-16"
          />
        </v-col>

        <v-col
          class="text-center"
          sm="6"
        />
      </v-row>
    </v-container>
    <!-- Plans Dashboard -->
    <quick-quote-page
      v-if="displayDashboard"
      id="Dashboard"
      :optionsname="$options.name"
    >
      <template #content>
        <plan-table
          v-if="users.isAdmin && dataReady"
          ref="dashboardAllPlansTable"
          :enable-all-customers="true"
        />

        <guest-dashboard-table
          v-if="users.isGuest && dataReady"
          ref="dashboardPlanTable"
        />

        <estimator-dashboard-table
          v-if="users.isEstimator && dataReady"
          ref="dashboardEstimatorTable"
        />
      </template>
    </quick-quote-page>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import QuickQuotePage from '@/components/QuickQuotePage'
  import GuestDashboardTable from '@/components/dashboard/tables/GuestDashboardTable'
  import PlanTable from '@/components/maintenance/tables/PlanTable'
  import EstimatorDashboardTable from '@/components/dashboard/tables/EstimatorDashboardTable'

  export default {
    name: 'DashboardView',

    components: {
      PlanTable,
      QuickQuotePage,
      GuestDashboardTable,
      EstimatorDashboardTable,
    },

    data () {
      return {}
    },

    computed: {
      ...get('user', [
        'image',
        'images',
      ]),
      users: get('user/users'),
      customers: get('customer/customers'),
      plans: get('plan/plans'),
      displayAsPlural: get('plan/displayAsPlural'),
      displayDashboard () {
        return this.users.isAdmin || this.users.isGuest || this.users.isEstimator
      },
      dataReady () {
        return this.getLengthOf(this.customers.data) > 0 && this.getLengthOf(this.plans.data) > 0
      },
    },

    created () {
      this.$store.dispatch('user/setPageName', this.$options.name)
    },
  }
</script>
